<template>
  <div class="network-data">
    <div class="network-token-wrap">
      <img class="token-icon" v-if="network.icon" :src="network.icon" alt="" />
      <div class="token-icon token-icon-skeleton" v-else></div>
      <img
        v-if="network.networkIcon"
        class="network-icon"
        :src="network.networkIcon"
        alt=""
      />
      <div class="network-icon network-icon-skeleton" v-else></div>
    </div>
    <div class="network-name text-ellipsis" v-if="network.permitId">
      <span v-if="locale === 'zh'">
        {{ network.titleZh }}
      </span>
      <span v-else>
        {{ network.titleEn }}
      </span>
    </div>
    <div v-else class="network-name network-name-skeleton"></div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useI18n } from "vue-i18n";
const { locale } = useI18n()
const props = defineProps({
  network: {
    type: Object,
  },
});
</script>

<style lang="scss" scoped>
.network-data {
  display: flex;
  align-items: center;
  .network-token-wrap {
    position: relative;
    .token-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .token-icon-skeleton {
      background: #e4e7eb;
    }
    .network-icon {
      position: absolute;
      bottom: 0;
      right: -5px;
      width: 18px;
      height: 18px;
      border: 2px solid #fff;
      border-radius: 50%;
    }
    .token-icon-skeleton {
      background: #e4e7eb;
    }
  }
  .network-name {
    width: 150px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #1a202c;
  }
  .network-name-skeleton {
    width: 191px;
    height: 24px;
    background: #e9ecf0;
    border-radius: 24px;
  }
}
@media screen and (max-width: 767px) {
  .network-data {
    .network-token-wrap {
      .token-icon {
        width: 26px;
        height: 26px;
      }
      .network-icon {
        width: 15px;
        height: 15px;
      }
    }
    .network-name {
      font-size: 15px;
    }
    .network-name-skeleton {
      width: 182px;
    }
  }
}
</style>
