import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
// import coinbaseWalletModule from '@web3-onboard/coinbase';

const injected = injectedModule();
// const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true });
// const walletConnect = walletConnectModule();

const walletConnect = walletConnectModule({
  version: 2, // **New Param** Defaults to version: 1 - this behavior will be deprecated after the WalletConnect v1 sunset
  handleUri: uri => console.log(uri),
  projectId: '481534ab92e5a69d1215857fdab22f45', // ***New Param* Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
  requiredChains:[1] // chains required to be supported by WC wallet
})

const rpcUrl = `https://web3.mytokenpocket.vip`;

const _chains = [
  {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl,
  },
  // {
  //   id: '0x38',
  //   token: 'BNB',
  //   label: 'Binance Smart Chain',
  //   rpcUrl: 'https://bsc-dataseed.binance.org/',
  // },
  {
    id: '0x61',
    token: 'tBNB',
    label: 'Binance Smart Chain Testnet',
    rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
  },
  // {
  //   id: '0x89',
  //   token: 'MATIC',
  //   label: 'Matic Mainnet',
  //   rpcUrl: 'https://matic-mainnet.chainstacklabs.com',
  // },
];


 export const onboard =  Onboard({
  wallets: [injected, walletConnect],
  chains: _chains,
  appMetadata: {
    name: 'ETH Staking Vault',
    icon: require('../assets/home/connect-logo.png'),
    description: 'Support Both Self-Custodial Staking and Small Amount Staking',
  },
  accountCenter: {
    desktop: {
      enabled: false,
    },
  },
  theme: 'light',
  i18n: {
    zh: {
      connect: {
        selectingWallet: {
          header: '可选钱包',
          sidebar: {
            heading: '快速开始',
            subheading: '连接你的钱包',
            paragraph: '连接钱包是指在Web3进行登录. 选择你的钱包以开始',
            IDontHaveAWallet: "我没有钱包"
          },
          recommendedWalletsPart1: '{app} only supports',
          recommendedWalletsPart2:
            'on this platform. Please use or install one of the supported wallets to continue',
          installWallet:
            'You do not have any wallets installed that {app} supports, please use a supported wallet',
          agreement: {
            agree: 'I agree to the',
            terms: 'Terms & Conditions',
            and: 'and',
            privacy: 'Privacy Policy',
          },
          whyDontISeeMyWallet: "为什么没看到我的钱包?",
          learnMore: "点击此处了解更多",
        },
        connectingWallet: {
          header:
            '{connectionRejected, select, false {正在连接到 {wallet}...} other {连接被拒绝}}',
          sidebar: {
            subheading: '授权连接',
            paragraph: '请在你的钱包内授权地址访问以继续.',
          },
          mainText: '连接中...',
          paragraph: '请确选择要授予访问权限的所有帐户.',
          rejectedText: '连接被拒绝',
          rejectedCTA: '点击这里重新尝试',
          primaryButton: '返回钱包列表',
        },
        connectedWallet: {
          header: '连接成功',
          sidebar: {
            subheading: '连接成功!',
            paragraph: '你的钱包现在已经连接到 {app}',
          },
          mainText: '已连接',
        },
      },
      modals: {
        actionRequired: {
          heading: '需要在 {wallet} 内进行操作',
          paragraph: '请在钱包内切换地址.',
          linkText: '了解更多.',
          buttonText: '好的',
        },
        switchChain: {
          heading: 'Switch Chain',
          paragraph1:
            '{app} requires that you switch your wallet to the {nextNetworkName} network to continue.',
          paragraph2:
            '*Some wallets may not support changing networks. If you can not change networks in your wallet you may consider switching to a different wallet.',
        },
        confirmDisconnectAll: {
          heading: 'Disconnect all Wallets',
          description:
            'Are you sure that you would like to disconnect all your wallets?',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
      },
    },
  },
});
