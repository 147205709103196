import _ from 'lodash';

export const CHAIN_INFO_MAP = {
  ETH: {
    WRAP_TOKENS: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
      approveProxy: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [
          'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        ],
        blockExplorerUrls: ['https://cn.etherscan.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '1',
    explorer: 'http://etherscan.io/tx/',
    addressExplorer: 'https://etherscan.com/address/{account}',
    tokenExplorer: 'https://etherscan.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 1,
    stableTokens: [
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  BSC: {
    WRAP_TOKENS: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    BAL_CONTRACT: '0xe83ff9a4b82b5d5cb5256e805c564f0a663e3080',
    AGGR_INFO: {
      contract: '0x00000047bb99ea4d791bb749d970de71ee0b1a34',
      // approveProxy: '0xeD1afC8C4604958C2F38a3408FA63B32E737c428',
      approveProxy: '0x00000047bb99ea4d791bb749d970de71ee0b1a34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x38',
        chainName: 'BNB Chain',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed3.defibit.io'],
        blockExplorerUrls: ['https://bscscan.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '12',
    explorer: 'https://bscscan.com/tx/',
    addressExplorer: 'https://bscscan.com/address/{account}',
    tokenExplorer: 'https://bscscan.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 56,
    stableTokens: [
      '0x55d398326f99059ff775485246999027b3197955',
      '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    ],
  },
  HECO: {
    WRAP_TOKENS: ['0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F'],
    BAL_CONTRACT: '0xa7f46a7bd0be90cd5dc39b906108443bcb30b418',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      // approveProxy: '0xDEa59458dF745Ed2BA6F1343458287f7608f9c4f',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x80',
        chainName: 'HECO Chain',
        nativeCurrency: {
          name: 'HT',
          symbol: 'HT',
          decimals: 18,
        },
        rpcUrls: ['https://http-mainnet-node.huobichain.com'],
        blockExplorerUrls: ['https://hecoinfo.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '15',
    explorer: 'https://hecoinfo.com/tx/',
    addressExplorer: 'https://hecoinfo.com/address/{account}',
    tokenExplorer: 'https://hecoinfo.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 128,
    stableTokens: [
      '0x0298c2b32eae4da002a15f36fdf7615bea3da047',
      '0xa71edc38d189767582c38a3145b5873052c3e47a',
    ],
  },
  MATIC: {
    WRAP_TOKENS: ['0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'],
    BAL_CONTRACT: '0xfe8657C2522DfE79d6eC4AB2534CC9468B7a9F6B',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x89',
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '18',
    explorer: 'https://polygonscan.com/tx/',
    addressExplorer: 'https://polygonscan.com/address/{account}',
    tokenExplorer: 'https://polygonscan.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 137,
    stableTokens: [
      '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    ],
  },
  // 'HSC': {
  //     WRAP_TOKENS: ['0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87'],
  //     BAL_CONTRACT: "0x16A715DcB38600d3E0C308b94D768dCF4faCb399",
  //     AGGR_INFO: {
  //         contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
  //         approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',

  //     },
  //     CHAIN_PARAMS: [{
  //         chainId: "0x46",
  //         chainName: "Hoo Smart Chain",
  //         nativeCurrency: {
  //             name: "HOO",
  //             symbol: "HOO",
  //             decimals: 18
  //         },
  //         rpcUrls: ["https://http-mainnet.hoosmartchain.com"],
  //         blockExplorerUrls: ['https://hooscan.com/']
  //     }],
  //     blockchain_id: '19',
  //     explorer: 'https://hooscan.com/tx/',
  //     addressExplorer: 'https://hooscan.com/address/{account}',
  //     isEVM: true,
  //     CHAIN_ID: 70,
  //     stableTokens: [
  //         '0x92a0bd4584c147d1b0e8f9185db0bda10b05ed7e',
  //         '0xd16babe52980554520f6da505df4d1b124c815a7'
  //     ]
  // },
  FTM: {
    WRAP_TOKENS: ['0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'],
    BAL_CONTRACT: '0xAE2941f648ad7abe4abf5C557c635EBd82e6A194',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xfa',
        chainName: 'Fantom',
        nativeCurrency: {
          name: 'FTM',
          symbol: 'FTM',
          decimals: 18,
        },
        rpcUrls: ['https://rpcapi.fantom.network'],
        blockExplorerUrls: ['https://ftmscan.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '25',
    explorer: 'https://ftmscan.com/tx/',
    addressExplorer: 'https://ftmscan.com/address/{account}',
    tokenExplorer: 'https://ftmscan.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 250,
    stableTokens: [
      '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    ],
  },
  OKTC: {
    WRAP_TOKENS: ['0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15'],
    BAL_CONTRACT: '0x2d64a09f36b61662fdab6faee3a49d6681c5816c',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x42',
        chainName: 'OKChain',
        nativeCurrency: {
          name: 'OKT',
          symbol: 'OKT',
          decimals: 18,
        },
        rpcUrls: ['https://exchainrpc.okex.org'],
        blockExplorerUrls: ['https://www.oklink.com/okexchain/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '20',
    explorer: 'https://www.oklink.com/okc/tx/',
    addressExplorer: 'https://www.oklink.com/okexchain/address/{account}',
    tokenExplorer: 'https://www.oklink.com/cn/oktc/token/{account}',
    isEVM: true,
    CHAIN_ID: 66,
    stableTokens: [
      '0x382bb369d343125bfb2117af9c149795c6c65c50',
      '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
      '0x332730a4f6e03d9c55829435f10360e13cfa41ff',
    ],
  },
  ARB: {
    WRAP_TOKENS: ['0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'],
    BAL_CONTRACT: '0xcF10501Ed85b4b66282310cAa0f3dabEf15D8e51',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa4b1',
        chainName: 'Arbitrum',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '24',
    explorer: 'https://arbiscan.io/tx/',
    addressExplorer: 'https://arbiscan.io/address/{account}',
    tokenExplorer: 'https://arbiscan.io/token/{account}',
    isEVM: true,
    CHAIN_ID: 42161,
    stableTokens: [
      '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    ],
  },
  AVAX: {
    WRAP_TOKENS: ['0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'],
    BAL_CONTRACT: '0xe7281717FbA2D77e1D85CF3b8b6E069a8a69B213',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa86a',
        chainName: 'Avalanche',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '23',
    explorer: 'https://snowtrace.io/tx/',
    addressExplorer: 'https://cchain.explorer.avax.network/address/{account}',
    tokenExplorer: 'https://snowtrace.io/token/{account}',
    isEVM: true,
    CHAIN_ID: 43114,
    stableTokens: [
      '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
      '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
      '0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB',
    ],
  },
  KCC: {
    WRAP_TOKENS: ['0x4446fc4eb47f2f6586f9faab68b3498f86c07521'],
    BAL_CONTRACT: '0x0F3c008865B730BE411E6619c46bE425b5A3b9CD',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x141',
        chainName: 'KCC Mainnet',
        nativeCurrency: {
          name: 'KCS',
          symbol: 'KCS',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mainnet.kcc.network'],
        blockExplorerUrls: ['https://explorer.kcc.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '35',
    explorer: 'https://explorer.kcc.io/en/tx/',
    addressExplorer: 'https://explorer.kcc.io/en/address/{account}',
    tokenExplorer: 'https://explorer.kcc.io/en/token/{account}',
    isEVM: true,
    CHAIN_ID: 321,
    stableTokens: [
      '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
      '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430',
    ],
  },
  CFX: {
    WRAP_TOKENS: ['0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b'],
    BAL_CONTRACT: '0x16A715DcB38600d3E0C308b94D768dCF4faCb399',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x406',
        chainName: 'Conflux eSpace',
        nativeCurrency: {
          name: 'CFX',
          symbol: 'CFX',
          decimals: 18,
        },
        rpcUrls: ['https://evm.confluxrpc.com'],
        blockExplorerUrls: ['https://evm.confluxscan.net/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '36',
    explorer: 'https://evm.confluxscan.net/tx/',
    addressExplorer: 'https://evm.confluxscan.net/address/{account}',
    tokenExplorer: 'https://evm.confluxscan.net/token/{account}',
    isEVM: true,
    CHAIN_ID: 1030,
    stableTokens: [
      '0xfe97e85d13abd9c1c33384e796f10b73905637ce',
      '0x6963efed0ab40f6c3d7bda44a05dcf1437c44372',
    ],
  },
  GLMR: {
    WRAP_TOKENS: ['0xacc15dc74880c9944775448304b263d191c6077f'],
    BAL_CONTRACT: '0x58F51a75759E21B257AB1d65C9B07e378CD66454',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x504',
        chainName: 'Moonbeam',
        nativeCurrency: {
          name: 'GLMR',
          symbol: 'GLMR',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.api.moonbeam.network'],
        blockExplorerUrls: ['https://moonscan.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '31',
    explorer: 'https://moonscan.io/tx/',
    addressExplorer: 'https://moonscan.io/address/{account}',
    tokenExplorer: 'https://moonscan.io/token/{account}',
    isEVM: true,
    CHAIN_ID: 1284,
    stableTokens: [
      '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
      '0x931715FEE2d06333043d11F658C8CE934aC61D0c',
      '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
      '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    ],
  },
  KLAY: {
    WRAP_TOKENS: [],
    BAL_CONTRACT: '0x5e3f7e652271142531f14e8bc89f77f112b820a7',
    AGGR_INFO: {
      contract: '0x9865EeBdD1cE65f45b6247AEEd2fA2252ECA7A08',
      approveProxy: '0x9865EeBdD1cE65f45b6247AEEd2fA2252ECA7A08',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x2019',
        chainName: 'Klaytn',
        nativeCurrency: {
          name: 'KLAY',
          symbol: 'KLAY',
          decimals: 18,
        },
        rpcUrls: ['https://klaytno.mytokenpocket.vip'],
        blockExplorerUrls: ['https://scope.klaytn.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '22',
    explorer: 'https://scope.klaytn.com/tx/',
    addressExplorer: 'https://scope.klaytn.com/account/{account}',
    tokenExplorer: 'https://scope.klaytn.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 8217,
    stableTokens: ['0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167'],
  },
  OP: {
    WRAP_TOKENS: ['0x4200000000000000000000000000000000000006'],
    BAL_CONTRACT: '0x9ff92249ae03fee969ba998655dc807854b29a7b',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa',
        chainName: 'Optimism',
        nativeCurrency: {
          name: 'OETH',
          symbol: 'OETH',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.optimism.io/'],
        blockExplorerUrls: ['https://optimistic.etherscan.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '26',
    explorer: 'https://optimistic.etherscan.io/tx/',
    addressExplorer: 'https://optimistic.etherscan.io/address/{account}',
    tokenExplorer: 'https://optimistic.etherscan.io/token/{account}',
    isEVM: true,
    CHAIN_ID: 10,
    stableTokens: [
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    ],
  },
  ETC: {
    WRAP_TOKENS: [
      '0x82A618305706B14e7bcf2592D4B9324A366b6dAd',
      '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
    ],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      // approveProxy: '0xeb2202B5646c237e93057bE912a3Cc4f4FDa290e',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x3d',
        chainName: 'Ethereum Classic',
        nativeCurrency: {
          name: 'ETC',
          symbol: 'ETC',
          decimals: 18,
        },
        rpcUrls: ['https://www.ethercluster.com/etc'],
        blockExplorerUrls: ['https://blockscout.com/etc/mainnet/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '41',
    explorer: 'https://blockscout.com/etc/mainnet/tx/',
    addressExplorer: 'https://blockscout.com/etc/mainnet/address/{account}',
    tokenExplorer: 'https://blockscout.com/etc/mainnet/address/{account}',
    isEVM: true,
    CHAIN_ID: 61,
    stableTokens: [
      '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
      '0xc9baa8cfdde8e328787e29b4b078abf2dadc2055',
    ],
  },
  ETHW: {
    WRAP_TOKENS: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x2711',
        chainName: 'ETHPoW Mainnet',
        nativeCurrency: {
          name: 'ETHW',
          symbol: 'ETHW',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.ethereumpow.org'],
        blockExplorerUrls: ['https://www.oklink.com/en/ethw/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: null,
    explorer: 'https://www.oklink.com/en/ethw/tx/',
    addressExplorer: 'https://www.oklink.com/cn/ethw/address/{account}',
    tokenExplorer: 'https://www.oklink.com/cn/ethw/token/{account}',
    isEVM: true,
    CHAIN_ID: 10001,
    stableTokens: [
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  ETHF: {
    WRAP_TOKENS: ['0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x7d44c',
        chainName: 'EthereumFair ETHF',
        nativeCurrency: {
          name: 'ETHF',
          symbol: 'ETHF',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.etherfair.org'],
        blockExplorerUrls: ['https://explorer.etherfair.org'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: null,
    explorer: 'https://explorer.etherfair.org/tx/',
    addressExplorer: 'https://www.oklink.com/cn/ethf/address/{account}',
    tokenExplorer: 'https://www.oklink.com/cn/ethf/token/{account}',
    isEVM: true,
    CHAIN_ID: 513100,
    stableTokens: [
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  'zkSync Era': {
    WRAP_TOKENS: ['0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91'],
    BAL_CONTRACT: '0x8E2912Ab852dd7347213ECe57F022b45BfD79538',
    AGGR_INFO: {
      contract: '0x65dE59507CaA235c8cA49BFD008CADA4d846CAA7',
      approveProxy: '0x65dE59507CaA235c8cA49BFD008CADA4d846CAA7',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x144',
        chainName: 'zkSync Era Mainnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://zksync2-mainnet.zksync.io'],
        blockExplorerUrls: ['https://explorer.zksync.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '46',
    explorer: 'https://explorer.zksync.io/tx/',
    addressExplorer: 'https://explorer.zksync.io/address/{account}',
    tokenExplorer: 'https://explorer.zksync.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 324,
    stableTokens: ['0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4'],
  },
  'EOS EVM': {
    WRAP_TOKENS: ['0xc00592aA41D32D137dC480d9f6d0Df19b860104F'],
    BAL_CONTRACT: '0x16A715DcB38600d3E0C308b94D768dCF4faCb399',
    AGGR_INFO: {
      contract: '0x12B0b0B068cE0c9C1ea64661fEd9fAB22E401240',
      approveProxy: '0x12B0b0B068cE0c9C1ea64661fEd9fAB22E401240',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x4571',
        chainName: 'EOS EVM',
        nativeCurrency: {
          name: 'EOS',
          symbol: 'EOS',
          decimals: 18,
        },
        rpcUrls: ['https://api.evm.eosnetwork.com'],
        blockExplorerUrls: ['https://explorer.evm.eosnetwork.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '47',
    explorer: 'https://explorer.evm.eosnetwork.com/tx/',
    addressExplorer: 'https://explorer.evm.eosnetwork.com/address/{account}',
    tokenExplorer: 'https://explorer.evm.eosnetwork.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 17777,
    stableTokens: ['0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'],
  },
  Base: {
    WRAP_TOKENS: ['0x4200000000000000000000000000000000000006'],
    BAL_CONTRACT: '0xC4Ac6c720ef076f18d500ec35eA035D2575221CC',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x2105',
        chainName: 'Base',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://developer-access-mainnet.base.org'],
        blockExplorerUrls: ['https://basescan.org/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    explorer: 'https://basescan.org/tx/',
    addressExplorer: 'https://basescan.org/address/{account}',
    tokenExplorer: 'https://basescan.org/token/{account}',
    isEVM: true,
    CHAIN_ID: 8453,
    blockchain_id: '51',
  },
  LINEA: {
    WRAP_TOKENS: ['0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
      approveProxy: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xe708',
        chainName: 'Linea',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [
          'https://linea-mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8',
        ],
        blockExplorerUrls: ['https://lineascan.build/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '50',
    explorer: 'https://lineascan.build/tx/',
    addressExplorer: 'https://lineascan.build/address/{account}',
    tokenExplorer: 'https://lineascan.build/token/{account}',
    isEVM: true,
    CHAIN_ID: 59144,
    stableTokens: [],
  },
  OPBNB: {
    // WRAP_TOKENS: ["0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f"],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    // AGGR_INFO: {
    //     contract: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
    //     approveProxy: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',

    // },
    CHAIN_PARAMS: [
      {
        chainId: '0xcc',
        chainName: 'opBNB',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://opbnb-mainnet-rpc.bnbchain.org'],
        blockExplorerUrls: ['https://mainnet.opbnbscan.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '52',
    explorer: 'https://mainnet.opbnbscan.com/tx/',
    addressExplorer: 'https://mainnet.opbnbscan.com/address/{account}',
    tokenExplorer: 'https://mainnet.opbnbscan.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 204,
    stableTokens: [],
  },
  Mantle: {
    WRAP_TOKENS: ['0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
      approveProxy: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1388',
        chainName: 'Mantle',
        nativeCurrency: {
          name: 'MNT',
          symbol: 'MNT',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.mantle.xyz'],
        blockExplorerUrls: ['https://explorer.mantle.xyz/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '49',
    explorer: 'https://explorer.mantle.xyz/tx/',
    addressExplorer: 'https://explorer.mantle.xyz/address/{account}',
    tokenExplorer: 'https://explorer.mantle.xyz/token/{account}',
    isEVM: true,
    CHAIN_ID: 5000,
    stableTokens: [],
  },
  ZetaChain: {
    WRAP_TOKENS: ['0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1b58',
        chainName: 'ZETA',
        nativeCurrency: {
          name: 'ZETA',
          symbol: 'ZETA',
          decimals: 18,
        },
        rpcUrls: ['https://zetachain-evm.blockpi.network/v1/rpc/public'],
        blockExplorerUrls: ['https://zetachain.blockscout.com/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '62',
    explorer: 'https://zetachain.blockscout.com/tx/',
    addressExplorer: 'https://zetachain.blockscout.com/address/{account}',
    tokenExplorer: 'https://zetachain.blockscout.com/token/{account}',
    isEVM: true,
    CHAIN_ID: 7000,
    stableTokens: [],
  },
  SCROLL: {
    WRAP_TOKENS: ['0x5300000000000000000000000000000000000004'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      // contract: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
      // approveProxy: '0x00000047bB99ea4D791bb749D970DE71EE0b1A34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x82750',
        chainName: 'Scroll',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.scroll.io'],
        blockExplorerUrls: ['https://blockscout.scroll.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '56',
    explorer: 'https://blockscout.scroll.io/tx/',
    addressExplorer: 'https://blockscout.scroll.io/address/{account}',
    tokenExplorer: 'https://blockscout.scroll.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 534352,
    stableTokens: [],
  },
  CORE: {
    WRAP_TOKENS: ['0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f'],
    BAL_CONTRACT: '0x16A715DcB38600d3E0C308b94D768dCF4faCb399',
    AGGR_INFO: {
      contract: '0x00000047bb99ea4d791bb749d970de71ee0b1a34',
      approveProxy: '0x00000047bb99ea4d791bb749d970de71ee0b1a34',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x45c',
        chainName: 'Core',
        nativeCurrency: {
          name: 'CORE',
          symbol: 'CORE',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.coredao.org/'],
        blockExplorerUrls: ['https://scan.coredao.org/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '54',
    explorer: 'https://scan.coredao.org/tx/',
    addressExplorer: 'https://scan.coredao.org/address/{account}',
    isEVM: true,
    CHAIN_ID: 1116,
    stableTokens: [],
  },
  Kroma: {
    WRAP_TOKENS: ['0x4200000000000000000000000000000000000001'],
    BAL_CONTRACT: '0xb78A2817e08D9FC6701C62A43A41cE81bB6CAF3d',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xff',
        chainName: 'Kroma',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://api.kroma.network/'],
        blockExplorerUrls: ['https://blockscout.kroma.network/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '63',
    explorer: 'https://blockscout.kroma.network/tx/',
    addressExplorer: 'https://blockscout.kroma.network/address/{account}',
    isEVM: true,
    CHAIN_ID: 255,
    stableTokens: [],
  },
  Merlin: {
    WRAP_TOKENS: ['0xF6D226f9Dc15d9bB51182815b320D3fBE324e1bA'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1068',
        chainName: 'Merlin',
        nativeCurrency: {
          name: 'BTC',
          symbol: 'BTC',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.merlinchain.io'],
        blockExplorerUrls: ['https://scan.merlinchain.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '64',
    explorer: 'https://scan.merlinchain.io/tx/',
    addressExplorer: 'https://scan.merlinchain.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 4200,
    stableTokens: [],
  },
  Manta: {
    WRAP_TOKENS: ['0x0dc808adce2099a9f62aa87d9670745aba741746'],
    BAL_CONTRACT: '0xC4Ac6c720ef076f18d500ec35eA035D2575221CC',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa9',
        chainName: 'Manta',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://pacific-rpc.manta.network/http'],
        blockExplorerUrls: ['https://manta.socialscan.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '58',
    explorer: 'https://manta.socialscan.io/tx/',
    addressExplorer: 'https://manta.socialscan.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 169,
    stableTokens: [],
  },
  Blast: {
    WRAP_TOKENS: ['0x4300000000000000000000000000000000000004'],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0x07964f135f276412b3182a3B2407b8dd45000000',
      approveProxy: '0x07964f135f276412b3182a3B2407b8dd45000000',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x13e31',
        chainName: 'Blast',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://blast.blockpi.network/v1/rpc/public'],
        blockExplorerUrls: ['https://blastscan.io/'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '65',
    explorer: 'https://blastscan.io/tx/',
    addressExplorer: 'https://blastscan.io/address/{account}',
    tokenExplorer: 'https://blastscan.io/token/{account}',
    isEVM: true,
    CHAIN_ID: 81457,
    stableTokens: [],
  },
  TRX: {
    WRAP_TOKENS: ['TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR'],
    BAL_CONTRACT: 'TWSaaayu3N1z5GKeWYyTkUG1p9tw3tdTHw',
    AGGR_INFO: {
      contract: 'TKqQ7zuL7yKp1NWuhGpoXTDiE3j2qiAbRE',
      // approveProxy: 'TTLaNDdcL5rMfxMS2VL1UCa44ebRCNbqew',
      approveProxy: 'TKqQ7zuL7yKp1NWuhGpoXTDiE3j2qiAbRE',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1750c',
        chainName: 'TRON Mainnet',
      },
    ],
    addressRegExp: '^[T][a-km-zA-HJ-NP-Z1-9]{25,34}$',
    blockchain_id: '10',
    explorer: 'https://tronscan.io/#/transaction/',
    addressExplorer: 'https://tronscan.io/#/address/{account}',
    tokenExplorer: 'https://tronscan.io/#/token20/{account}',
    isEVM: false,
    CHAIN_ID: 95500,
  },

  SOL: {
    addressRegExp: '^[a-km-zA-HJ-NP-Z1-9]{42,46}$',
    isEVM: false,
    blockchain_id: '27',
    explorer: 'https://explorer.solana.com/tx/',
    addressExplorer: 'https://solscan.io/account/{account}',
    tokenExplorer: 'https://solscan.io/token/{account}',
  },
  EOS: {
    addressRegExp: '',
    blockchain_id: '4',
    addressExplorer: 'http://bloks.io/account/{account}',
    tokenExplorer: 'http://bloks.io/tokens/{account}',
  },
  BTC: {
    addressRegExp: '[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$',
    blockchain_id: '11',
    addressExplorer: 'https://www.blockchain.com/btc/address/{account}',
    tokenExplorer: 'https://www.blockchain.com/btc/address/{account}',
    network: {
      messagePrefix: '\x18Bitcoin Signed Message:\n',
      bech32: 'bc',
      bip32: {
        public: 76067358,
        private: 76066276,
      },
      pubKeyHash: 0,
      scriptHash: 5,
      wif: 128,
    },
  },
  DOGE: {
    addressRegExp: 'D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}',
    blockchain_id: '44',
    addressExplorer: 'https://blockchair.com/dogecoin/address/{account}',
    tokenExplorer: 'https://blockchair.com/dogecoin/address/{account}',
    network: {
      messagePrefix: '\x19Dogecoin Signed Message:\n',
      bip32: {
        public: 0x02facafd,
        private: 0x02fac398,
      },
      pubKeyHash: 0x1e,
      scripthash: 0x16,
      wif: 0x9e,
    },
  },
  DOT: {
    addressRegExp: '^[1][a-km-zA-HJ-NP-Z1-9]{46,48}$',
    blockchain_id: '13',
    addressExplorer: 'https://polkadot.subscan.io/account/{account}',
    tokenExplorer: 'https://polkadot.subscan.io/account/{account}',
  },
  KSM: {
    addressRegExp: '',
    blockchain_id: '14',
    addressExplorer: 'https://kusama.subscan.io/account/{account}',
    tokenExplorer: 'https://kusama.subscan.io/account/{account}',
  },
  IOST: {
    addressRegExp: '',
    blockchain_id: '7',
    addressExplorer: 'https://explorer.iost.io/account/{account}',
    tokenExplorer: 'https://explorer.iost.io/token/{account}',
  },
  APTOS: {
    addressRegExp: '0x[a-fA-F0-9]{64}$',
    blockchain_id: '43',
    AGGR_INFO: {},
    explorer: 'https://explorer.aptoslabs.com/txn/',
    addressExplorer: 'https://explorer.aptoslabs.com/account/{account}',
    tokenExplorer: 'https://explorer.aptoslabs.com/account/{account}',
  },
  BSCTEST: {
    CHAIN_PARAMS: [
      {
        chainId: '0x61',
        chainName: 'BSCTEST',
        nativeCurrency: {
          name: 'Binance Chain Native Token',
          symbol: 'tBNB',
          decimals: 18,
        },
        rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
      },
    ],
    addressRegExp: '^(0x)[0-9A-Fa-f]{40}$',
    blockchain_id: '82',
    isEVM: true,
    CHAIN_ID: 97,
  },
};

export const CHAINID_NETWORK = _.invert(
  _.mapValues(
    _.omitBy(CHAIN_INFO_MAP, (item) => !item.CHAIN_ID),
    (item) => item.CHAIN_ID
  )
);
