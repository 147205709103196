import Axios from 'axios';
import { stringify } from 'qs';

const request = Axios.create({
  timeout: 30000,
});

request.defaults.headers.post['Content-Type'] = 'application/json';

request.interceptors.request.use(
  (config) => {
    config.data = stringify(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
  },
  (error) => {
    console.log('request error: ', error);
    return Promise.reject(error);
  }
);

export default request;
