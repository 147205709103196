export default {
  comingSoon: 'Coming Soon',
  language: 'English',
  settings: 'Settings',

  home: {
    check: 'Check',
    claim: 'Claim',
    claimTitle_1: 'Claim',
    claimTitle_1_1: 'Distribute',
    claimTitle_2: 'Your Airdrop',
    claimTitle_3: 'Easier',
    claimed: 'Claimed',
    airdropDesc:
      'The better and easier way to claim your airdrop and distribute your token to the community.',
    initiateAirdrop: 'Start Airdrop',
    connect: 'Connect',
    networkError: 'Network Error',
    back: 'Back',
    eligibleToClaim: "Congratulations, you're eligible!",
    notEligibleToClaim: "You're not eligible for this airdrop",
    claimEnd: 'Claiming will be ended in',
    receive: 'You will receive',
    placeholder: 'Please enter your wallet address',
    addressRequired: 'Wallet address is required',
    endTimeMessage: "We're sorry, claim period ended.",
    wrongAddress: 'Query wallet is different from connected wallet, please reconnect',
    addressVaLid: 'Wrong address format, please check and try again',
    checkError: 'Error Code:-1, try again later',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
};
