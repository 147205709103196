export default {
  comingSoon: '敬请期待',
  language: '简体中文',
  settings: '语言设置',

  home: {
    check: '检查',
    claim: '领取',
    claimTitle_1: '领取',
    claimTitle_1_1: '分发',
    claimTitle_2: '更轻松地',
    claimTitle_3: '您的空投',
    claimed: '已领取',
    airdropDesc: '让您更好地领取空投，以及更方便地向您的社区发送空投',
    initiateAirdrop: '发起空投',
    connect: '连接钱包',
    networkError: '错误网络',
    back: '返回',
    eligibleToClaim: '恭喜！您符合领取资格！',
    notEligibleToClaim: '很抱歉，您不符合本次空投领取资格！',
    claimEnd: '领取将结束于',
    receive: '您将收到',
    placeholder: '请输入地址',
    addressRequired: '请输入钱包地址',
    endTimeMessage: '领取时间已结束，无法领取！',
    wrongAddress: '查询钱包与连接的钱包不同，请重新连接',
    addressVaLid: '地址格式不正确，请检查后重试',
    checkError: 'Error Code:-1，请稍后重试',
    days: '天',
    hours: '小时',
    minutes: '分',
    seconds: '秒',
  },
};
