<template>
  <div class="home">
    <Header @connect="onConnect" @openLanguageModal="onaLanguageModal" />
    <div class="home-container">
      <div class="home-left">
        <div class="pc-title-wrap">
          <div
            class="title"
            :class="{
              claim: locale === 'en',
              blue: locale === 'en' && textFlag1,
            }"
          >
            {{ locale === 'zh' ? $t('home.claimTitle_2') : textEn }}
          </div>
          <div
            class="title"
            :class="{
              claim: locale === 'zh',
              blue: locale === 'zh' && textFlag2,
            }"
          >
            {{ locale === 'zh' ? textZh : $t('home.claimTitle_2') }}
          </div>
          <div class="title">{{ $t('home.claimTitle_3') }}</div>
        </div>
        <div class="mobile-title-wrap">
          <div v-if="locale === 'zh'">
            <div class="mobile-title-zh">
              <div
                class="title"
                :class="{
                  claim: locale === 'en',
                  blue: locale === 'en' && textFlag1,
                }"
              >
                {{ locale === 'zh' ? $t('home.claimTitle_2') : textEn }}
              </div>
              <div
                class="title"
                :class="{
                  claim: locale === 'zh',
                  blue: locale === 'zh' && textFlag2,
                }"
              >
                {{ locale === 'zh' ? textZh : $t('home.claimTitle_2') }}
              </div>
            </div>
            <div class="title">{{ $t('home.claimTitle_3') }}</div>
          </div>
          <div v-if="locale === 'en'">
            <div
              class="title"
              :class="{
                claim: locale === 'en',
                blue: locale === 'en' && textFlag1,
              }"
            >
              {{ locale === 'zh' ? $t('home.claimTitle_2') : textEn }}
            </div>
            <div class="mobile-title-zh mobile-title-en">
              <div
                class="title"
                :class="{
                  claim: locale === 'zh',
                  blue: locale === 'zh' && textFlag2,
                }"
              >
                {{ locale === 'zh' ? textZh : $t('home.claimTitle_2') }}
              </div>
              <div class="title claim-title-3">{{ $t('home.claimTitle_3') }}</div>
            </div>
          </div>
        </div>
        <div class="claim-desc">
          {{ $t('home.airdropDesc') }}
        </div>
        <div class="button" @click="initiateAirdrop">
          {{ $t('home.initiateAirdrop') }}
        </div>
      </div>
      <div
        class="home-right"
        :class="{
          'not-airdrop': airdropStatus === 'not-airdrop',
          'claim-airdrop': airdropStatus === 'claim-airdrop',
        }"
      >
        <img
          v-if="airdropStatus === 'claim-airdrop'"
          class="back-img"
          @click="onBack"
          src="../assets/home/back.png"
          alt=""
        />
        <template
          v-if="
            airdropStatus === 'not-airdrop' || airdropStatus === 'before-check'
          "
        >
          <div class="select-network-wrap" @click.stop="openNetworkList">
            <NetworkData :network="state.currentNetwork" />
            <img
              class="network-arrow"
              v-if="state.currentNetwork.chainId"
              :src="arrowImg"
              alt=""
            />
            <div
              class="network-list-content"
              @click.stop="closeNetworkList"
              v-show="isNetworkList"
            >
              <div class="network-list">
                <div
                  class="network-item"
                  v-for="(item, index) in state.airdropList"
                  :key="index"
                  @click.stop="onSelectNetwork(item, index)"
                >
                  <NetworkData :network="item" />
                  <img
                    v-if="state.currentNetworkIndex === index"
                    class="network-arrow"
                    src="../assets/home/selected.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="desc"
            v-if="locale === 'en'"
            v-html="state.currentNetwork.descriptionsEn"
          ></div>
          <div
            class="desc"
            v-else
            v-html="state.currentNetwork.descriptionsZh"
          ></div>
          <div class="input-wrap">
            <input
              type="text"
              v-model="account"
              @input="inputChange"
              :placeholder="$t('home.placeholder')"
            />
            <div class="check-button" @click="onCheck">
              {{ $t('home.check') }}
            </div>
          </div>
          <div class="not-eligible-airdrop">
            {{ tipsText }}
          </div>
          <div class="TP-airdrop-wrap" v-if="isAirdropEnd">
            <img
              class="TP-airdrop-img"
              src="../assets/home/TP-airdrop.png"
              alt=""
            />
          </div>
          <div class="airdrop-claim-count-down-wrap" v-else>
            <div class="claim-end">{{ $t('home.claimEnd') }}</div>
            <div class="airdrop-claim-count-down">
              <TimeBox
                v-for="(item, index) in state.endTimeList"
                :key="index"
                :timeData="item"
              />
            </div>
          </div>
        </template>
        <div
          class="claim-receive-wrap"
          v-if="airdropStatus === 'claim-airdrop'"
        >
          <div class="title">{{ $t('home.eligibleToClaim') }}</div>
          <div class="claim-receive">{{ $t('home.receive') }}</div>
          <div class="claim-receive-amount">
            <div class="network-token-wrap">
              <img
                class="token-icon"
                v-if="state.claimData.icon"
                :src="state.claimData.icon"
                alt=""
              />
              <img
                class="network-icon"
                v-if="state.currentNetwork.networkIcon"
                :src="state.currentNetwork.networkIcon"
                alt=""
              />
            </div>
            <span v-if="state.claimData.amount">
              {{ state.claimData.amount + ' ' + state.claimData.symbol }}
            </span>
          </div>
          <div class="wrong-address" v-if="isErrorAddress">
            {{ t('home.wrongAddress') }}
          </div>
          <div
            class="connect-claim-button"
            :class="{ disabled: isDisabledButton }"
            @click="onClaim"
          >
            {{ connectClaimText }}
          </div>
          <div class="back-button" @click="onBack">{{ $t('home.back') }}</div>
        </div>
      </div>
    </div>
    <div class="coming-soon" v-show="isComingSoon">{{ $t('comingSoon') }}</div>
    <Modal v-if="languageModal">
      <div class="language-content">
        <div class="language-header">
          <div class="title">{{ $t('settings') }}</div>
        </div>
        <div class="line"></div>
        <div class="language-list">
          <div
            class="language-item"
            v-for="(item, index) in state.languageList"
            :key="index"
            @click="changeLang(item.lang)"
          >
            <span>{{ item.label }}</span>
            <img
              v-if="locale === item.lang"
              src="../assets/home/selected.png"
              alt=""
            />
          </div>
        </div>
        <img
          class="modal-close"
          src="../assets/home/close.png"
          alt=""
          @click="closeLanguageModal"
        />
      </div>
    </Modal>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from 'vue';
// import 'element-plus/dist/index.css';
// import { ElMessage } from 'element-plus';
import Web3 from 'web3';
import { BigNumber } from 'bignumber.js';

import { useI18n } from 'vue-i18n';
const { locale, t } = useI18n();

import useLoginTP from '../hooks/useLoginTP';
const { loginTP, switchTPWallet } = useLoginTP();

import { onboard } from '../utils/onboard';
import { CHAIN_INFO_MAP, CHAINID_NETWORK } from '../utils/constants';

import { useCommonStore } from '../stores/common';
const common = useCommonStore();

import { setMode, dev, parseWithUnit } from '../utils';
import { reqAirdropList, reqAirdropChecking, reqBlockChainList } from '../api';

import Header from '../components/layouts/Header.vue';
import TimeBox from '@/components/tools/TimeBox.vue';
import NetworkData from '@/components/tools/NetworkData.vue';
import Modal from '@/components/tools/Modal.vue';

let intervalId = null;
let setTimeoutId = null;
let setTimeoutId1 = null;
let setTimeoutId2 = null;

const airdropStatus = ref('before-check');
const endTimestamp = ref(0);
const account = ref('');
const tipsText = ref('');
const isComingSoon = ref(false);
const isNetworkList = ref(false);
const languageModal = ref(false);
const canClaim = ref(false);
const testText = ref('');
const textEn = ref('');
const textZh = ref('');
const textFlag1 = ref(false);
const textFlag2 = ref(false);
const isAddressVaLid = ref(false);
const checkError = ref(false);
const decimals = ref(0);
const state = reactive({
  endTimeList: [
    {
      timeCount: '00',
      timeText: t('home.days'),
    },
    {
      timeCount: '00',
      timeText: t('home.hours'),
    },
    {
      timeCount: '00',
      timeText: t('home.minutes'),
    },
    {
      timeCount: '00',
      timeText: t('home.seconds'),
    },
  ],
  currentNetwork: {},
  currentNetworkIndex: 0,
  airdropList: [],
  web3: null,
  evmProvider: null,
  unsubscribe: null,
  claimData: {
    icon: '',
    symbol: '',
  },
  timeoutIDs: [],
  blockChainList: [],
  languageList: [
    { lang: 'zh', label: '简体中文' },
    { lang: 'en', label: 'English' },
  ],
});

onMounted(() => {
  walletSubscribe();
  getBlockChainList();
  timerChangeText('en');
  timerChangeText('zh');
  if (common.isTP) {
    loginTP();
  }
  document.body.addEventListener('click', () => {
    isNetworkList.value = false;
  });
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  if (setTimeoutId) {
    clearInterval(setTimeoutId);
  }
  if (setTimeoutId1) {
    clearInterval(setTimeoutId1);
  }
  if (setTimeoutId2) {
    clearInterval(setTimeoutId2);
  }
});

watch(
  () => common.isDark,
  (newValue, oldValue) => {
    let theme = 'light';
    if (newValue) {
      theme = 'dark';
    }
    onboard.state.actions.updateTheme(theme);
  },
  { deep: false }
);

watch(
  () => locale,
  (newValue, oldValue) => {
    state.endTimeList[0].timeText = t('home.days');
    state.endTimeList[1].timeText = t('home.hours');
    state.endTimeList[2].timeText = t('home.minutes');
    state.endTimeList[3].timeText = t('home.seconds');
  },
  { deep: true }
);

watch(
  [() => common.connected, () => state.currentNetwork],
  (newValue, oldValue) => {
    const [newConnected, newCurrentNetwork] = newValue;
    // console.log('newConnected', newConnected);
    // console.log('newCurrentNetwork', newCurrentNetwork);
    account.value = common.connected.account;
    inputChange();
    airdropStatus.value = 'before-check'
    if (common.isTP) {
      if (newConnected.ns !== 'ethereum') {
        common.networkError = true;
      } else {
        if (
          newConnected.chainId &&
          newConnected.chainId !== parseInt(state.currentNetwork.chainId) &&
          newCurrentNetwork.chainId &&
          newConnected.chainId !== parseInt(newCurrentNetwork.chainId)
        ) {
          common.networkError = true;
        } else {
          common.networkError = false;
        }
      }
    } else if (
      newConnected.chainId &&
      newConnected.chainId !== parseInt(state.currentNetwork.chainId) &&
      newCurrentNetwork.chainId &&
      newConnected.chainId !== parseInt(newCurrentNetwork.chainId)
    ) {
      common.networkError = true;
    } else {
      common.networkError = false;
    }
  },
  { deep: true }
);

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const isZh = computed(() => {
  return locale.value === 'zh';
});

const isDisabledButton = computed(() => {
  if (
    canClaim.value ||
    isErrorAddress.value ||
    (common.connected.account &&
      !common.networkError &&
      state.claimData.status === 2)
  ) {
    if (common.networkError) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
});

const isErrorAddress = computed(() => {
  if (account.value.trim() && common.connected.account) {
    return (
      common.connected.account.toLocaleLowerCase() !==
      account.value.toLocaleLowerCase()
    );
  } else {
    return false;
  }
});

const arrowImg = computed(() => {
  return require(`../assets/home/arrow-down${darkModeImg.value}.png`);
});

const connectClaimText = computed(() => {
  if (common.connected.account) {
    if (common.networkError) {
      return t('home.networkError');
    } else {
      if (state.claimData.status === 1) {
        return t('home.claim');
      } else {
        return t('home.claimed');
      }
    }
  } else {
    return t('home.connect');
  }
});

const isAirdropEnd = computed(() => {
  if (endTimestamp.value > new Date().getTime() / 1000) {
    return false;
  } else {
    return true;
  }
});

const inputChange = () => {
  // console.log('account', account.value);
  if (state.currentNetwork.chainId) {
    const type = CHAINID_NETWORK[state.currentNetwork.chainId];
    const addressRegExp = CHAIN_INFO_MAP[type].addressRegExp;
    const regExp = new RegExp(addressRegExp);
    if (regExp.test(account.value)) {
      // console.log('地址正确');
      isAddressVaLid.value = true;
    } else {
      // console.log('地址错误');
      isAddressVaLid.value = false;
    }
  }
};

const timerChangeText = (lang) => {
  if (lang === 'en') {
    textFlag1.value = false;
    textEn.value = 'Claim';
    setTimeoutId1 = setInterval(() => {
      if (textFlag1.value) {
        textEn.value = 'Claim';
      } else {
        textEn.value = 'Distribute';
      }
      textFlag1.value = !textFlag1.value;
    }, 3000);
  } else {
    textFlag2.value = false;
    textZh.value = '领取';
    setTimeoutId2 = setInterval(() => {
      if (textFlag2.value) {
        textZh.value = '领取';
      } else {
        textZh.value = '分发';
      }
      textFlag2.value = !textFlag2.value;
    }, 3000);
  }
};

const onaLanguageModal = () => {
  languageModal.value = true;
  document.body.style.overflow = 'hidden';
};

const closeLanguageModal = () => {
  languageModal.value = false;
  document.body.style.overflow = 'auto';
};

const changeLang = (lang) => {
  locale.value = lang;
};

const getTipsText = () => {
  if (!isAddressVaLid.value) {
    return t('home.addressVaLid');
  } else if (airdropStatus.value === 'before-check') {
    if (!account.value.trim()) {
      return t('home.addressRequired');
    } else if (endTimestamp.value < new Date().getTime() / 1000) {
      return t('home.endTimeMessage');
    } else if (checkError.value) {
      return t('home.checkError');
    } else {
      return '';
    }
  } else if (airdropStatus.value === 'not-airdrop') {
    return t('home.notEligibleToClaim');
  } else {
    return '';
  }
};

const getBlockChainList = async () => {
  const data = {
    lang: 'zh-Hans',
    version: '0.0.1',
    os: 'web',
  };
  try {
    const res = await reqBlockChainList(data);
    if (res.result === 0 && res.data) {
      state.blockChainList = [];
      res.data.forEach((item) => {
        if (!item.deprecated && item.deprecated !== 1) {
          let obj = {
            chain_id: JSON.parse(item.metadata).chain_id,
            icon_url: item.icon_url,
            blockchain_id: item.hid,
          };
          if (obj.chain_id) {
            state.blockChainList.push(obj);
          }
        }
      });
      // console.log('blockChainList', state.blockChainList);
      localStorage.setItem(
        'blockChainList',
        JSON.stringify(state.blockChainList)
      );
    } else {
      state.blockChainList =
        JSON.parse(localStorage.getItem('blockChainList')) || [];
    }
    getAirdropList();
  } catch (error) {
    state.blockChainList =
      JSON.parse(localStorage.getItem('blockChainList')) || [];
    getAirdropList();
    console.log(error);
  }
};

const onClaim = () => {
  if (common.connected.account && !common.networkError) {
    if (state.claimData.status === 1) {
      claimToken();
    }
  } else {
    onConnect();
  }
};

const claimToken = () => {
  if (canClaim.value) {
    return;
  }
  canClaim.value = true;
  const myProvider = state.evmProvider || window.ethereum;
  const { contract, data } = state.claimData;
  let param = {
    from: common.connected.account,
    to: contract,
    data: data,
  };
  myProvider
    .request({
      method: 'eth_sendTransaction',
      params: [param],
    })
    .then((hash) => {
      console.log('hash', hash);
      // checkClaimStatus();
      // 拿到hash之后轮询去查询
      let setIntervalId = setInterval(() => {
        checkEVMTransitionByHash(hash, setIntervalId);
      }, 1000);
      state.timeoutIDs.push(setIntervalId);
    })
    .catch((err) => {
      console.log(err);
      canClaim.value = false;
    });
};

const checkClaimStatus = (setIntervalId) => {
  const data = {
    permitId: state.currentNetwork.permitId,
    account: account.value.trim(),
  };
  reqAirdropChecking(data).then((res) => {
    if (res.code === 0 && res.data) {
      res.data.amount = parseWithUnit(dev(parseFloat(res.data.amount), Math.pow(10, decimals.value)));
      state.claimData = { ...state.claimData, ...res.data };
      if (state.claimData.status === 1) {
        canClaim.value = false;
      } else {
        canClaim.value = true;
      }
    }
  });
};

const checkEVMTransitionByHash1 = (hash) => {
  state.web3.eth.getTransactionReceipt(hash).then((res) => {
    console.log('res', res);
    // if (res.status === 0n) {
    //   console.log('111');
    // }
  }).catch(() => {
    console.log('pending');
  });
};

const checkEVMTransitionByHash = (hash, setIntervalId) => {
  state.web3.eth.getTransactionReceipt(hash).then((res) => {
    // console.log('res', res);
    if (res && res.status === 1n) {
      clearTimeoutIds(setIntervalId)
      state.claimData.status = 2;
    } else if (res.status === 0n) {
      clearTimeoutIds(setIntervalId)
      state.claimData.status = 1;
    }
    setTimeout(() => {
      checkClaimStatus();
    }, 2000);
  }).catch((err) => {
    console.log(err);
  });
};

const clearTimeoutIds = (setIntervalId) => {
  for (let i = 0; i < state.timeoutIDs.length; i++) {
    if (setIntervalId === state.timeoutIDs[i]) {
      clearInterval(setIntervalId);
    }
  }
}

const onConnect = async () => {
  if (common.isTP) {
    if (common.networkError) {
      const blockchain = state.blockChainList.find(
        (item) => item.chain_id === state.currentNetwork.chainId
      );
      if (blockchain) {
        switchTPWallet(String(blockchain.blockchain_id));
      }
    } else {
      loginTP();
    }
  } else {
    if (common.networkError) {
      const type = CHAINID_NETWORK[state.currentNetwork.chainId];
      switchEthereumChain(type);
    } else {
      const wallet = await onboard.connectWallet();
      // console.log('wallet', wallet);
      if (wallet.length) {
        state.web3 = new Web3(wallet[0].provider);
        state.evmProvider = wallet[0].provider;
      }
      // checkEVMTransitionByHash1('0xf57eea2ef68ebacd7127fe51a463eece5f06a2c10ef9bf44c79f30b13e1a24db');
      if (state.web3) {
        common.connected.account = wallet[0]?.accounts[0]?.address;
        common.connected.chainId = parseInt(wallet[0]?.chains[0]?.id, 16);
      }
    }
  }
};

const switchEthereumChain = async (type) => {
  if (window.ethereum) {
    try {
      const chain_id = CHAIN_INFO_MAP[type].CHAIN_PARAMS[0]?.chainId;
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chain_id }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: CHAIN_INFO_MAP[type].CHAIN_PARAMS,
          });
          if (common.isTP) {
            location.reload();
          }
        } catch (addError) {
          console.log('addError', addError);
        }
      }
    }
  }
};

const walletSubscribe = () => {
  const wallets = onboard.state.select();
  const { unsubscribe } = wallets.subscribe((update) => {
    // console.log('update', update)
    if (update.wallets.length) {
      state.web3 = new Web3(update.wallets[0].provider);
      state.evmProvider = update.wallets[0].provider;
      if (state.web3) {
        common.connected.account = update.wallets[0]?.accounts[0]?.address;
        common.connected.chainId = parseInt(
          update.wallets[0]?.chains[0]?.id,
          16
        );
      }
    } else {
      // 断开连接
      common.connected.account = '';
      common.connected.chainId = '';
      state.web3 = null;
      state.evmProvider = null;
    }
  });
  state.unsubscribe = unsubscribe;
};

const initiateAirdrop = () => {
  if (!isComingSoon.value) {
    isComingSoon.value = true;
    setTimeoutId = setTimeout(() => {
      isComingSoon.value = false;
    }, 2000);
  }
};

const getAirdropList = async () => {
  try {
    const res = await reqAirdropList();
    if (res.code === 0 && res.data) {
      res.data.forEach((item) => {
        let network = state.blockChainList.find(
          (item1) => item1.chain_id === item.chainId
        );
        if (network) {
          item.networkIcon = network.icon_url;
        }
      });
      state.airdropList = res.data;
      // state.airdropList = [...res.data, ...res.data, ...res.data];
      state.currentNetwork = res.data[0] || {};
      state.claimData.symbol = res.data[0]?.symbol;
      state.claimData.icon = res.data[0]?.icon;
      decimals.value = res.data[0]?.decimals;
      endTimestamp.value = res.data[0]?.endTime;
      initCountdown();
    }
  } catch (error) {
    console.log(error);
  }
};

const openNetworkList = () => {
  isNetworkList.value = true;
  if (common.isMobile) {
    document.body.style.overflow = 'hidden';
  }
};

const closeNetworkList = () => {
  isNetworkList.value = false;
  document.body.style.overflow = 'auto';
};

const onSelectNetwork = (network, index) => {
  document.body.style.overflow = 'auto';
  state.currentNetworkIndex = index;
  state.currentNetwork = network;
  state.claimData.symbol = network.symbol;
  state.claimData.icon = network.icon;
  decimals.value = network.decimals;
  endTimestamp.value = network.endTime;
  isNetworkList.value = false;
  airdropStatus.value = 'before-check';
  tipsText.value = '';
  initCountdown();
};

const onBack = () => {
  airdropStatus.value = 'before-check';
};

const onCheck = async () => {
  // airdropStatus.value = 'claim-airdrop';
  checkError.value = false;
  tipsText.value = getTipsText();
  if (!account.value.trim()) {
    return;
  }
  if (endTimestamp.value < new Date().getTime() / 1000) {
    return;
  }
  if (!isAddressVaLid.value) {
    return;
  }
  try {
    const data = {
      permitId: state.currentNetwork.permitId,
      account: account.value.trim(),
    };
    const res = await reqAirdropChecking(data);
    if (res.code === 0 && res.data) {
      if (res.data.amount === '0') {
        airdropStatus.value = 'not-airdrop';
      } else {
        airdropStatus.value = 'claim-airdrop';
      }
      tipsText.value = getTipsText();
      res.data.amount = parseWithUnit(dev(parseFloat(res.data.amount), Math.pow(10, decimals.value)));
      state.claimData = { ...state.claimData, ...res.data };
    } else {
      checkError.value = true;
      tipsText.value = getTipsText();
    }
  } catch (error) {
    console.log(error);
  }
};

const initCountdown = () => {
  clearInterval(intervalId);
  // 获取更新剩余时间的函数
  const updateCountdown = countdown(endTimestamp.value * 1000);
  updateCountdown();
  // 每秒钟更新剩余时间
  intervalId = setInterval(updateCountdown, 1000);
};

const countdown = (endTimestamp) => {
  return function () {
    // 获取当前时间戳
    const now = new Date().getTime();
    // 计算剩余时间（单位：毫秒）
    const timeRemaining = endTimestamp - now;
    if (timeRemaining <= 0) {
      clearInterval(intervalId); // 倒计时结束时清除定时器
      state.endTimeList[0].timeCount = '00';
      state.endTimeList[1].timeCount = '00';
      state.endTimeList[2].timeCount = '00';
      state.endTimeList[3].timeCount = '00';
      return;
    }
    // 计算剩余时间的天数、小时数、分钟数和秒数
    let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    if (days < 10) {
      days = '0' + days;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    // 输出剩余时间
    // console.log('剩余天数：' + days);
    // console.log('剩余小时数：' + hours);
    // console.log('剩余分钟数：' + minutes);
    // console.log('剩余秒数：' + seconds);
    state.endTimeList[0].timeCount = days;
    state.endTimeList[1].timeCount = hours;
    state.endTimeList[2].timeCount = minutes;
    state.endTimeList[3].timeCount = seconds;
  };
};

const scrollIntoView = () => {
  const node = document.querySelector('.big-card-list');
  if (node) {
    node.scrollIntoView({
      behavior: 'smooth', // 平滑过渡
      block: 'center', // 居中
    });
  }
};

const changLang = () => {
  if (locale.value === 'zh') {
    locale.value = 'en';
  } else {
    locale.value = 'zh';
  }
};
</script>

<style lang="scss">
.el-icon {
  width: auto;
  height: auto;
  line-height: normal;
  svg {
    width: 50px;
    height: 50px;
  }
}
.home {
  @mixin button {
    color: #c0c3c6;
    width: fit-content;
    border-radius: 44px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    padding: 0 52px;
    height: 56px;
    line-height: 56px;
    cursor: pointer;
  }
  .home-container {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 90px auto 150px;
    .home-left {
      flex: 1;
      .pc-title-wrap {
        display: block;
      }
      .mobile-title-wrap {
        display: none;
      }
      .title {
        font-size: 74px;
        font-weight: 800;
        color: #2c3443;
        line-height: 86px;
      }
      .claim {
        color: #3fdd75;
      }
      .blue {
        color: #2980fe;
      }
      .claim-desc {
        margin-top: 12px;
        font-size: 18px;
        font-weight: 400;
        color: #7a7a7a;
      }
      .button {
        @include button();
        margin-top: 48px;
        border: 2px solid #929295;
        color: #7a7a7a;
      }
    }
    .home-right {
      position: relative;
      margin-left: 117px;
      width: 595px;
      min-height: 608px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0 4px 100px 0 rgba(7, 29, 71, 0.15);
      padding: 0 45px;
      &.claim-airdrop {
        .claim-receive-wrap {
          margin-top: 124px;
        }
      }
      .TP-airdrop-img {
        width: 290px;
        height: 224px;
      }
      .back-img {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 24px;
        top: 24px;
        cursor: pointer;
      }
      .select-network-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        height: 64px;
        background: #f5f6f7;
        border-radius: 48px;
        padding: 0 24px;
        margin: 46px auto 0;
        cursor: pointer;
        .network-arrow {
          margin-left: 16px;
          width: 25px;
          height: 24px;
        }
        .network-list-content {
          .network-list {
            position: absolute;
            z-index: 998;
            left: 0;
            top: 70px;
            width: 100%;
            max-height: 355px;
            padding: 6px 0;
            background: #ffffff;
            border: 1px solid #f1f3f5;
            border-radius: 13px;
            box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.08);
            overflow: auto;
            box-sizing: content-box;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .network-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 16px 24px;
              .network-arrow {
                width: 27px;
                height: 27px;
              }
            }
          }
        }
      }
      .desc {
        margin-top: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #9ea0a5;
      }
      .input-wrap {
        margin-top: 32px;
        height: 60px;
        padding: 0 8px 0 24px;
        background: #f5f6f7;
        border-radius: 48px;
        display: flex;
        align-items: center;
        input {
          height: 100%;
          flex: 1;
          background: none;
          border: none;
          outline: none;
          font-size: 16px;
          font-weight: 500;
          color: #3a3b3d;
          &::placeholder {
            color: #c0c3c6;
          }
        }
        .check-button {
          @include button();
          margin-left: 10px;
          height: 43px;
          line-height: 43px;
          background: #2980fe;
          padding: 0 38px;
        }
      }
      .not-eligible-airdrop {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        color: #f56459;
        height: 19px;
        line-height: 19px;
        text-align: center;
      }
      .TP-airdrop-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 0 44px;
      }
      .airdrop-claim-count-down-wrap {
        margin: 63px 0 108px;
        .claim-end {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          text-align: center;
        }
        .airdrop-claim-count-down {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          .time-box {
            &:nth-child(n + 2) {
              margin-left: 16px;
            }
          }
        }
      }
      .claim-receive-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 30px;
          font-weight: 600;
          color: #2980fe;
          text-align: center;
        }
        .claim-receive {
          margin-top: 24px;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          text-align: center;
        }
        .claim-receive-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          padding: 0 30px;
          .network-token-wrap {
            position: relative;
            .token-icon {
              width: 41px;
              height: 41px;
            }
            .network-icon {
              width: 22px;
              height: 22px;
              position: absolute;
              right: -2px;
              bottom: -2px;
              border: 2px solid #fff;
            }
          }
          img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
          }
          span {
            margin-left: 10px;
            font-size: 40px;
            font-weight: 600;
            color: #1a202c;
            text-align: center;
            word-break: break-all;
          }
        }
        .wrong-address {
          margin-top: 46px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #f56459;
        }
        .connect-claim-button {
          @include button();
          position: absolute;
          left: 50%;
          bottom: 178px;
          transform: translateX(-50%);
          background: #2980fe;
          height: 56px;
          line-height: 56px;
          font-weight: 700;
          padding: 0 80px;
          pointer-events: auto;
          white-space: nowrap;
          &.disabled {
            background: #c1dafe;
            pointer-events: none;
          }
        }
        .back-button {
          @include button();
          position: absolute;
          left: 50%;
          bottom: 92px;
          transform: translateX(-50%);
          color: #3a3b3d;
          height: 54px;
          line-height: 54px;
          background: #fff;
          border: 1px solid #e6e8ea;
          border-radius: 50px;
          height: 56px;
          line-height: 56px;
          font-weight: 700;
        }
      }
    }
  }
  .coming-soon {
    position: fixed;
    left: 50%;
    top: 435px;
    transform: translateX(-50%);
    width: 800px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid #e6e8ea;
    border-radius: 40px;
    // box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.08);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 60px;
  }
}

@media screen and (max-width: 1300px) {
  .home {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1024px) {
  .home {
    padding: 0 20px;
    .home-container {
      flex-direction: column;
      margin: 90px auto 200px;
      .home-left {
        .title {
          font-size: 40px;
          line-height: 50px;
          text-align: center;
          font-size: 34px;
        }
        .claim-desc {
          margin-top: 6px;
          font-size: 12px;
          padding: 0 24px;
          text-align: center;
        }
        .button {
          height: 36px;
          line-height: 36px;
          padding: 0 40px;
          margin: 20px auto 0;
        }
      }
      .home-right {
        margin: 50px auto 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .el-message {
    padding: 10px 15px;
    .el-icon {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  .home {
    background: url('../assets/home/bg-mobile.png') no-repeat center center;
    background-size: 100% auto;
    background-position: top 54px left;
    min-height: auto;
    padding: 0;
    .home-container {
      margin: 48px auto 88px;
      flex-direction: column;
      .home-left {
        .pc-title-wrap {
          display: none;
        }
        .mobile-title-wrap {
          display: block;
          .mobile-title-zh {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-title-en {
            display: flex;
            align-items: center;
            justify-content: center;
            .claim-title-3 {
              margin-left: 5px;
            }
          }
        }
        .title {
          font-size: 40px;
          line-height: 44px;
          text-align: center;
          font-size: 34px;
        }
        .claim-desc {
          margin-top: 6px;
          font-size: 14px;
          padding: 0 24px;
          text-align: center;
        }
        .button {
          height: 40px;
          line-height: 40px;
          padding: 0 40px;
          margin: 26px auto 0;
          border: 1px solid #929295;
          font-size: 15px;
        }
      }
      .home-right {
        width: calc(100% - 48px);
        margin: 32px 24px 0;
        padding: 50px 16px;
        padding: 0 27px;
        min-height: 494px;
        &.claim-airdrop {
          padding: 0 60px;
          .claim-receive-wrap {
            margin-top: 105px;
            .title {
              font-size: 24px;
            }
            .claim-receive {
              margin-top: 18px;
              font-size: 17px;
            }
            .claim-receive-amount {
              padding: 0;
              margin-top: 16px;
              .network-token-wrap {
                .token-icon {
                  width: 30px;
                  height: 30px;
                }
                .network-icon {
                  width: 16px;
                  height: 16px;
                }
              }
              span {
                font-size: 24px;
              }
            }
            .wrong-address {
              margin-top: 26px;
            }
            .connect-claim-button {
              bottom: 108px;
              height: 42px;
              line-height: 42px;
              font-size: 16px;
              padding: 0 54px;
            }
            .back-button {
              bottom: 32px;
              height: 42px;
              line-height: 42px;
              font-size: 16px;
              padding: 0 48px;
            }
          }
        }
        .TP-airdrop-img {
          width: 253px;
          height: 196px;
          opacity: 0.8;
        }
        .back-img {
          width: 24px;
          height: 24px;
        }
        .select-network-wrap {
          margin-top: 28px;
          height: 48px;
          padding: 0 16px;
          .network-arrow {
            width: 20px;
            height: 20px;
          }
          .network-list-content {
            position: fixed;
            z-index: 999;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.5);
            .network-list {
              width: calc(100% - 90px);
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              max-height: 435px;
              .network-item {
                padding: 16px 24px;
                .network-arrow {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
        .desc {
          font-size: 12px;
          line-height: 18px;
        }
        .input-wrap {
          margin-top: 36px;
          height: 50px;
          line-height: 50px;
          padding: 0 8px 0 16px;
          input {
            width: 100%;
            font-size: 12px;
          }
          .check-button {
            font-size: 12px;
            padding: 0 20px;
            height: 38px;
            line-height: 38px;
            font-weight: 700;
          }
        }
        .TP-airdrop-wrap {
        }
        .claim-receive-wrap {
        }
        .not-eligible-airdrop {
          margin-top: 29px;
          font-size: 12px;
        }
        .airdrop-claim-count-down-wrap {
          margin: 73px 0 88px;
          .claim-end {
            margin-top: 73px;
            font-size: 12px;
          }
          .airdrop-claim-count-down {
            margin-top: 16px;
          }
        }
      }
    }
    .coming-soon {
      width: 80%;
    }
    .language-content {
      position: relative;
      width: 80%;
      background: #fcfcfd;
      border-radius: 19px;
      box-shadow: 0px 122.03px 122.03px -91.52px rgba(31, 47, 70, 0.12);
      .language-header {
        padding: 0 24px;
        margin-top: 24px;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #3a3b3d;
        }
      }
      .line {
        margin-top: 21px;
        height: 2px;
        background: #e6e8ec;
      }
      .language-list {
        padding: 15px 24px;
        .language-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          span {
            font-size: 16px;
            font-weight: 500;
            color: #3a3b3d;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
      .modal-close {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 24px;
        top: 24px;
      }
    }
  }
}
</style>
