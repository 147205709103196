import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {
  state: () => {
    return {
      isDark: false,
      isMobile: false,
      isTP: false,
      account: '',
      connected: {
        chainId: '',
        account: '',
        ns: ''
      },
      networkError: false,
    };
  },
  actions: {
    changeMode(payload) {
      this.isDark = payload;
    },
  },
  getters: {
    // doubledCount: (state) => {
    //   return state.isDark;
    // },
  },
});
