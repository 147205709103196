import {
  TP_SERVER_DOMAIN,
  FORWARD_SERVER_DOMAIN,
  EXPLORER_TRANSIT_DOMAIN,
  TP_CLAIM_SERVER_DOMAIN
} from '../config';

import request from './request';

/**
 * 获取空投项目列表
 */
export function reqAirdropList() {
  return request.get(`${TP_CLAIM_SERVER_DOMAIN}/v1/airdrop/list`);
}

/**
 * 检查领取资格
 */
export function reqAirdropChecking(params) {
  return request.get(`${TP_CLAIM_SERVER_DOMAIN}/v1/airdrop/checking`, { params });
}

/**
 * 获取链logo
 * params lang version os
 */
export function reqBlockChainList(params) {
  return request.get(`${TP_SERVER_DOMAIN}/v1/block_chain/list`, { params });
}
