import { BigNumber } from "bignumber.js";

export function getSystemTheme() {
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)'); // 是深色
  // console.log('isDarkTheme', isDarkTheme);
  if (isDarkTheme.matches) {
    // 是深色
    return 'dark';
  } else {
    // 不是深色
    return 'light';
  }
}

/** 设置显示模式 */
export function setMode(mode) {
  if (mode) {
    document.documentElement.setAttribute('theme', 'dark');
  } else {
    document.documentElement.removeAttribute('theme');
  }
}

export function getIP() {
  return fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then((data) => data.ip)
    .catch((error) => console.error(error));
}

/** 加法 */
export function add(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.plus(b).toNumber());   //结果需要用toNumber转为普通数字
}

/** 减法 */
export function minus(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.minus(b).toNumber());
}

/** 乘法 */
export function mut(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.multipliedBy(b).toNumber());
}

/** 除法 */
export function dev(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.dividedBy(b).toNumber());
}

/** 科学计数法转数字 */
export function toNonExponential(num) {
  var m = num.toExponential().match(/\d(?:.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
}


export function parseWithUnit(val) {
  try {
    val = BigNumber(val)
    if ( val >= 1000000000) {
      return Math.round(val / 1000000) / 1000 + 'B';
    } else if (val < 1000000000 && val >= 1) {
      return val.decimalPlaces(4, BigNumber.ROUND_DOWN)
      // return val.toFixed(4);
    } else if (val < 1) {
      // return val.toFixed(6);
      return val.decimalPlaces(6, BigNumber.ROUND_DOWN)
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err, val);
  }
}
