import { useCommonStore } from '../stores/common';
import tp from 'tp-js-sdk';

/** 钱包连接hook */
export default function useLoginTP() {
  let common = useCommonStore();

  const loginTP = async () => {
    tp.getCurrentWallet().then((res) => {
      // console.log('wallet', res);
      if (res.result) {
        common.connected.account = res.data.address;
        common.connected.chainId = parseInt(window.ethereum.chainId, 16);
        common.connected.ns = res.data.ns;
      }
    });
  };

  const switchTPWallet = (blockchain_id) => {
    tp.getWallet({
      walletTypes: [blockchain_id],
      switch: true,
    })
      .then((res) => {
        if (res.result) {
          location.reload();
        }
      })
      .catch(console.log);
  };

  const getAccount = async () => {
    let account = await window.ethereum.request({
      method: 'eth_accounts',
    });
    // console.log('account', account);
    if (!account.length) {
      account = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
    }
    common.account = account[0];
    // return account;
  };

  return { loginTP, switchTPWallet, getAccount };
}
