<template>
  <div class="home-bg-img-wrap">
    <img class="home-bg-img" src="./assets/home/bg.png" alt="" />
  </div>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { useCommonStore } from './stores/common';
const common = useCommonStore();

onMounted(() => {
  common.isTP = navigator.userAgent.indexOf('TokenPocket') > -1;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|TokenPocket|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    common.isMobile = true;
  } else {
    common.isMobile = false;
  }
});
</script>

<style lang="scss" scoped>
.home-bg-img-wrap {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 1080px;
  pointer-events: none;
  .home-bg-img {
    width: 2600px;
    height: 1080px;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    object-fit: cover;
  }
}
@media screen and (max-width: 1024px) {
  .home-bg-img-wrap {
    .home-bg-img {
      width: 3132px;
      height: 1296px;
    }
  }
}
@media screen and (max-width: 767px) {
  .home-bg-img-wrap {
    display: none;
  }
}
</style>
