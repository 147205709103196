<template>
  <div class="modal">
    <!-- <div class="modal-content"> -->
      <!-- <div class="content">
      </div> -->
      <slot></slot>
      <!-- <img class="close" src="../../assets/home/back.png" @click="onClose" /> -->
    <!-- </div> -->
  </div>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale, t } = useI18n();

const props = defineProps({
  isTokenPocket: {
    type: Boolean
  }
})


const onClose = () => {
  console.log('close');
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 9998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    position: relative;
    background: #fff;
    border-radius: 8px;
    // .content {
    //   min-width: 600px;
    //   background: #ffffff;
    //   border-radius: 20px;
    //   box-shadow: 0 64px 64px -48px rgba(31, 47, 70, 0.12);
    //   padding: 32px 32px 60px;
    // }
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 767px) {
  .modal {
    // .modal-content {
    //   width: 75%;
    //   border-radius: 16px;
    //   .content {
    //   }
    //   .close {
    //     width: 28px;
    //     height: 28px;
    //   }
    // }
  }
}
</style>
