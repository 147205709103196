<template>
  <div class="header">
    <div class="header-left">
      <img class="logo" src="../../assets/home/logo.png" alt="" />
    </div>
    <div class="header-right">
      <div
        class="connect-button"
        :class="{ 'network-error': common.networkError }"
        @click="onConnect"
      >
        {{ connectText }}
      </div>
      <div class="language-wrap" @click="changeLang">
        <img src="../../assets/home/language.png" alt="" />
        <span>{{ t('language') }}</span>
      </div>
      <img
        class="language-mobile-icon"
        src="../../assets/home/language.png"
        alt=""
        @click="openLanguageModal"
      />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, computed, watch, onMounted, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale, t } = useI18n();

import { useCommonStore } from '../../stores/common';
const common = useCommonStore();

const emit = defineEmits(['connect', 'openLanguageModal']);

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const connectText = computed(() => {
  if (common.connected.account) {
    if (common.networkError) {
      return t('home.networkError');
    } else {
      return (
        common.connected.account.slice(0, 5) +
        '...' +
        common.connected.account.slice(-5)
      );
    }
  } else {
    return t('home.connect');
  }
});

const isZh = computed(() => {
  return locale.value === 'zh';
});

const openLanguageModal = () => {
  emit('openLanguageModal')
}

const changeLang = () => {
  if (locale.value === 'zh') {
    locale.value = 'en';
  } else {
    locale.value = 'zh';
  }
};

const onConnect = () => {
  emit('connect');
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  height: 60px;
  .header-left {
    display: flex;
    .logo {
      height: 28px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .connect-button {
      height: 35px;
      background: #ffffff;
      border-radius: 48px;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 500;
      color: #2980fe;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      &.network-error {
        color: #f56459;
        background: #ffecec;
      }
    }
    .language-wrap {
      margin-left: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
      }
      span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #1a202c;
      }
    }
    .language-mobile-icon {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .header {
    background: #fff;
    padding: 0 16px;
    height: 54px;
    .header-left {
      .logo {
        height: 22px;
      }
    }
    .header-right {
      .connect-button {
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        background: #ebf4ff;
      }
      .language-wrap {
        display: none;
      }
      .language-mobile-icon {
        display: block;
        margin-left: 10px;
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
